export { getIam };

const getIam = () => {
  const user = window.localStorage.getItem('[developer-portal]current_user');

  if (user) {
    return JSON.parse(user);
  }

  return null;
};
