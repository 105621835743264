import { has } from './helpers';

export {
  getConfig
};

const getConfig = () => {
  const config = has(window, 'slnConfig') ? window.slnConfig : {};
  const autostart = typeof config.autostart !== 'undefined' ? config.autostart : true;
  const logo = has(config, 'logo') ? config.logo : { img: { width: 0 } };
  const header = has(config, 'header') ? config.header : false;
  const footer = has(config, 'footer') ? config.footer : false;
  const roles = config.roles && config.roles !== '' ? config.roles : null;

  return {
    logo,
    header,
    footer,
    autostart,
    roles
  };
};
