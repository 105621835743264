// https://lodash.com/docs/4.17.15#get
// https://lodash.com/docs/4.17.15#has
import has from 'lodash/has';
import get from 'lodash/get';

import { getLanguage } from './language';
import { getIcon } from './assets';
import { getIam } from './iam';

export {
  has,
  get,
  renderProp,
  getBrand,
  brand,
  replacer,
  createEl,
  getVersion
};

const getVersion = () => {
  return process.env.VERSION;
};

/**
 * return a prop from a data object if present
 * @param {object} data e.g. { user: { name: 'John' } }
 * @param {string} props e.g. 'user.name'
 * @param {string} fallback e.g. No name
 * @return {string} e.g. John
 */
const renderProp = (data, props, fallback) => {
  if(has(data, props)){
    return get(data, props);
  }
  return fallback || '';
};

/** get the brand code */
const getBrand = () => {
  return process.env.BRAND;
};

const brand = getBrand();
const iam = getIam();

const replacer = (str) => {
  return str.replace('{language}', getLanguage());
};

const createEl = (tag, options = {}) => {
  let el = document.createElement(tag); 

  if(options.classes) {
    el.className = options.classes;
  }

  if(options.id) {
    el.id = options.id;
  }

  if(options.html) {
    el.innerHTML = options.html;
    
    if(options.token && iam) {
      el.innerHTML = el.innerHTML
        .replace('{firstname}', iam.firstName)
        .replace('{lastname}', iam.lastName)
        .replace('{username}', iam.userName)
        .replace('{email}', iam.email);
    }
  }


  if(options.href) {
    el.href = options.href;
  }
  
  if(options.title) {
    el.title = options.title;
  }
  
  if(options.target) {
    el.target = options.target;
  }
  
  if(options.rel) {
    el.rel = options.rel;
  }

  if(options.action) {
    //NOSONAR
    el.onclick = new Function(options.action.arguments, options.action.body);
  }

  if(options.dataId) {
    el.setAttribute('data-id', options.dataId);
  }

  if(options.attributes) {
    options.attributes.forEach(attr => {
      el.setAttribute(attr.name, attr.value);
    });
  }

  if(options.tag) {
    const tag = createEl('div', {
      classes: 'sln-tag',
      html: options.tag
    });
    el.appendChild(tag);
  }
  
  if(options.linkIcon) {
    const tag = createEl('div', {
      classes: options.classes + '__icon',
      html: getIcon(options.linkIcon)
    });
    el.appendChild(tag);
  }

  return el;
};
