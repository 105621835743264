import { ids } from '../constants/ids';
import { getData } from '../utils/rest';
import { getConfig } from '../utils/config';

import { template } from './header.tmpl';
import { has, replacer, createEl } from '../utils/helpers';
import { setLabels } from '../utils/labels';
import { addEvent, emitEvent, events, removeEvent } from '../utils/events';
import { setLanguage } from '../utils/language';
import { destroy } from '../index';

export {
  startHeader,
  inject,
  bindElements,
  bind,
  css,
  destroyHeader,
  closeAllMenus,
  openPanel,
  createHeader,
  setStyles,
};

// variables to keep track of
let header;
let openMenus = [];
let openElement;
let els = {};

const css = {
  // dropdown menus
  menuToggle: 'sln-header__primary__link--dropdown',
  menu: 'sln-header__menu',
  menuLink: 'sln-header__menu__link',
  menuOpen: 'sln-header__menu--open',
  menuClose: 'sln-header__menu__close',
  menuWrapperActive: 'sln-header__primary__wrapper--active',

  // panels
  panelToggle: 'sln-header__primary__link',
  panelOpen: 'sln-header__panel--open',
  panelClose: 'sln-header__panel__close',
  panelNavigation: 'sln-header__panel--navigation',
  panelLocked: 'sln-header__panel--locked',

  // panel primary & language
  panelMenu: 'sln-header__panel__menu',
  panelMenuOpen: 'sln-header__panel__menu--open',
  panelMenuLink: 'sln-header__panel__menu__link',
  panelMenuClose: 'sln-header__panel__menu__close',
  panelMenuToggle: 'sln-header__panel__menu__toggle',

  // mask
  mask: 'sln-header__mask',
  maskOpen: 'sln-header__mask--open',

  // body lock
  bodyLock: 'sln-lock',
};

const createHeader = () => {
  let header = document.getElementById(ids.header);

  if (!header) {
    header = createEl('header', {
      id: 'sln-header',
      classes: 'sln-header',
    });

    document.body.insertAdjacentElement('afterbegin', header);
  }
};

/**
 * inject the header skeleton and fetch data
 */
const startHeader = () => {
  const content = getConfig().header;

  const logo = getConfig().logo;
  const type = typeof content;

  try {
    if (type === 'string') {
      createHeader();

      getData(replacer(content))
        .then((data) => {
          if (data) {
            inject(data, template, () => {
              bind();
            });
            setStyles(logo);
          }
        })
        .catch((err) => {
          console.error('ign-header error');
          console.error(err);
          destroyHeader();
        });
    } else if (type === 'object') {
      createHeader();

      inject(content, template, () => {
        bind();
      });
      setStyles(logo);
    } else {
      console.error('header type error');
    }
    
  }
  catch(error) {
    console.error('SLN rendering error');
    console.error(error);
    destroy();
  }
};

/**
 * inject the required template into the header
 * @param {object} data to populate the header
 * @param {function} t template string to use
 * @param {function} callback for post injection
 */
const inject = (data, template, cb) => {
  destroyHeader();
  createHeader();

  header = document.getElementById(ids.header);

  if (header) {
    // set the general labels that are used in a few templates
    if (has(data, 'labels')) {
      setLabels(data.labels);
    }

    header.appendChild(template(data));

    if (typeof cb === 'function') {
      cb();
    }
  }
};

const destroyHeader = () => {
  const header = document.getElementById(ids.header);
  const styles = document.getElementById(ids.styles);

  if (header) {
    header.parentNode.removeChild(header);
  }
  if (styles) {
    styles.parentNode.removeChild(styles);
  }
};

const setStyles = (logo) => {
  const styles = `#main{margin-top:62px !important}.sln-with-banner #main{margin-top:104px !important;}@media(min-width:1000px){#main{margin-top:86px !important}.sln-with-banner #main{margin-top:126px !important}}.sln-header__logo,.sln-footer__logo{width:${logo.img.mobileWidth}px}@media(min-width:700px){.sln-header__logo, .sln-footer__logo{width:${logo.img.width}px}}`;
  const style = createEl('style', {
    id: ids.styles,
    html: styles,
  });

  document.head.appendChild(style);
};

/** bind all interactive header elements */
const bind = () => {
  els = bindElements();
  bindMenuToggles();
  bindPanelToggles();
  bindPanelMenuToggles();
  bindLanguage();
};

/** grab the elements we need from the dom */
const bindElements = () => {
  return {
    // dropdown menus
    menuToggles: header.querySelectorAll(`.${css.menuToggle}`),
    menuClose: header.querySelectorAll(`.${css.menuClose}`),
    menuLinks: header.querySelectorAll(
      `.${css.menuLink}, .${css.panelMenuLink}`
    ),

    // panels
    panelToggles: header.querySelectorAll(`.${css.panelToggle}[data-id]`),
    panelClose: header.querySelectorAll(`.${css.panelClose}`),
    panelNavigation: header.querySelector(`.${css.panelNavigation}`),

    // panel primary & language
    panelMenus: header.querySelectorAll(`.${css.panelMenu}`),
    panelMenuToggles: header.querySelectorAll(`.${css.panelMenuToggle}`),
    panelMenuClose: header.querySelectorAll(`.${css.panelMenuClose}`),

    // mask
    mask: header.querySelector(`.${css.mask}`),
  };
};

/** LANGUAGE */

/** binding language links */
const bindLanguage = () => {
  els.menuLinks.forEach((el) => {
    if (el.hasAttribute('data-id')) {
      addEvent(el, 'click', handleLanguageClick);
    }
  });
};

/** handle user clicking on language link */
const handleLanguageClick = (e) => {
  e.preventDefault();
  const lang = e.currentTarget.getAttribute('data-id');

  setLanguage(lang);
  closeAllMenus();
};

// Menu events

/** bindings for header dropdown menus */
const bindMenuToggles = () => {
  els.menuToggles.forEach((el) => {
    const parent = el.parentNode;

    addEvent(el, 'click', toggleMenu);
    addEvent(parent, 'mouseenter', handleMenuMouseenter);
    addEvent(parent, 'mouseleave', handleMenuMouseleave);
  });
};

/**
 * toggles a header dropdown menu
 */
const toggleMenu = (e) => {
  const el = e.currentTarget.nextElementSibling;

  if (el) {
    e.preventDefault();
    e.stopPropagation();
  }
};

/** open header dropdown menus on mouseenter */
const handleMenuMouseenter = (e) => {
  const el = e.target;
  const parent = el.parentNode;
  const menu = el.querySelector(`.${css.menu}`);

  if (menu) {
    parent.classList.add(css.menuWrapperActive);
    addOpenMenu(menu, css.menuOpen);
  }
};

/** close header dropdown menus on mouseleave */
const handleMenuMouseleave = (e) => {
  const el = e.target;
  const parent = el.parentNode;

  parent.classList.remove(css.menuWrapperActive);
  closeAllMenus();
};

/**
 * push a new open menu object to track
 */
const addOpenMenu = (el, classes, menus = openMenus) => {
  emitEvent(events.elemOpening, el);

  el.classList.add(classes);
  menus.push({
    el,
    classes,
  });
};

/** PANELS */

/** opens a side panel if it can be found */
const openPanel = (e) => {
  const id = e.currentTarget.getAttribute('data-id');
  const el = document.getElementById(id);

  if (el) {
    e.preventDefault();
    e.stopPropagation();

    openElement = el;

    closeAllMenus();
    showMask();
    addOpenMenu(el, css.panelOpen);
    lockBody();
    addEvent(document, 'click', outsideClick);
  }

  // if the panel can't be found we let the href run
};

/** bindings for panel toggles */
const bindPanelToggles = () => {
  els.panelToggles.forEach((el) => {
    addEvent(el, 'click', openPanel);
  });

  els.panelClose.forEach((el) => {
    addEvent(el, 'click', () => closeAllMenus());
  });
};

/** open the associated panel menu if it exists */
const openPanelMenu = (e) => {
  const el = e.currentTarget;
  const next = el.nextElementSibling;

  if (next) {
    if(next.classList.contains(css.panelMenu)) {
      e.preventDefault();

      lockPanelNavigation();
      addOpenMenu(next, css.panelMenuOpen);
    }
  }

  if(el.hasAttribute('data-id')) {
    openPanel(e);
  }
};

/** close all open panel menus */
const closeAllPanelMenus = () => {
  els.panelMenus.forEach((el) => {
    el.classList.remove(css.panelMenuOpen);
  });

  unlockPanelNavigation();
};

/** bind menus inside panels */
const bindPanelMenuToggles = () => {
  els.panelMenuToggles.forEach((el) => {
    addEvent(el, 'click', openPanelMenu);
  });

  els.panelMenuClose.forEach((el) => {
    addEvent(el, 'click', closeAllPanelMenus);
  });
};

/** when the panel has a submenu open we need to stop the parent scrolling */
const lockPanelNavigation = () => {
  if (els.panelNavigation) {
    els.panelNavigation.classList.add(css.panelLocked);
  }
};

/** unlock the navigation panel to allow scrolling once more */
const unlockPanelNavigation = () => {
  if (els.panelNavigation) {
    els.panelNavigation.classList.remove(css.panelLocked);
  }
};

/** MASK */

/** show the panel mask */
const showMask = () => {
  if (els.mask) {
    els.mask.classList.add(css.maskOpen);
  }
};

/** hide the panel mask */
const hideMask = () => {
  if (els.mask) {
    els.mask.classList.remove(css.maskOpen);
  }
};

/** LOCKS */

/** lock the body to prevent background scrolling */
const lockBody = () => {
  const doc = document.documentElement;
  doc.classList.add(css.bodyLock);
};

/** unlock the body and allow scrolling again */
const unlockBody = () => {
  const doc = document.documentElement;

  if (doc.classList.contains(css.bodyLock)) {
    // only allow when panel is finished animating out (300ms)
    window.setTimeout(() => {
      doc.classList.remove(css.bodyLock);
    }, 300);
  }
};

/** close any open menus */
const closeAllMenus = (menus = openMenus) => {
  menus.forEach((menu) => {
    emitEvent(events.elemClosing, menu.el);
    menu.el.classList.remove(menu.classes);
  });

  menus.splice(0, menus.length);

  unlockBody();
  unlockPanelNavigation();
  hideMask();
  // closeBanner();
};

/** if user clicks outside of the open menu we want to close it */
const outsideClick = (e) => {
  if (!openElement.contains(e.target)) {
    closeAllMenus();
    removeEvent(document, 'click', outsideClick);
  }
};
